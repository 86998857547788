import { defaultStyles } from '@/utils/defaultStyles';

function Column(props: {
  contents: string[];
  isFirst?: boolean;
  isLast?: boolean;
}) {
  return (
    <div className="flex flex-col">
      <div
        className={`${props.isFirst ? 'bg-[#0D81FF] text-white rounded-tl-[16px] font-bold' : 'bg-white text-[#0D81FF]'} ${props.isLast && 'rounded-tr-[16px]'} border border-dotted border-[#E5E5E5] border-l-0 border-t-0 border-r-0 p-3 text-[16px]`}
      >
        {props.contents[0]}
      </div>
      <div
        className={`${props.isFirst ? 'bg-[#0D81FF] text-white' : 'bg-white text-[#0D81FF] font-bold'} border border-dotted border-[#E5E5E5] border-l-0 border-t-0 border-r-0 p-3 text-[16px]`}
      >
        {props.contents[1]}
      </div>
      <div
        className={`${props.isFirst ? 'bg-[#0D81FF] text-white' : 'bg-white text-[#0D81FF] font-bold'} border border-dotted border-[#E5E5E5] border-l-0 border-t-0 border-r-0 p-3 text-[16px]`}
      >
        {props.contents[2]}
      </div>
      <div
        className={`${props.isFirst ? 'bg-[#0D81FF] text-white rounded-bl-[16px]' : 'bg-white text-[#0D81FF] font-bold'} ${props.isLast && 'rounded-br-[16px]'} p-3 text-[16px]`}
      >
        {props.contents[3]}
      </div>
    </div>
  );
}

const smallItems = ['300 zł/h', '1100 zł/4h', '2100 zł/8h'];

const columnsItems = [
  ['Ilość osób', 'Do 15 osób', '16-30 osób', 'pow. 30 osób'],
  ['do 3 godzin', '500 zł/h', '600 zł/h', '700 zł/h'],
  ['4 - 8 godzin', '600 zł/h', '700 zł/h', '800 zł/h'],
  ['pow. 8 godzin', '700 zł/h', '800 zł/h', '900 zł/h'],
];

export default function PricingSection() {
  return (
    <section
      className={`flex flex-col bg-white py-24 ${defaultStyles.paddingBig}`}
      id="pricing"
    >
      <div
        className={`flex flex-row justify-between px-0 lg:px-12 xl:px-18 2xl:px-32`}
      >
        <div className="flex flex-col xl:flex-row gap-8 xl:gap-4 justify-between w-full">
          <div className="flex flex-col gap-8">
            <div className="text-[40px] text-black">Cennik</div>
            <div className="text-[#8AA7C8] text-[16px]">
              *Poniższe ceny są podane w kwotach netto
            </div>
            <button
              onClick={() =>
                (
                  document.getElementById(
                    'basic-modal',
                  ) as HTMLDialogElement | null
                )?.showModal()
              }
              className={`cursor-pointer text-[16px] bg-[#0D81FF] text-white font-semibold flex flex-row justify-center items-center outline-none rounded-[63px] py-4 px-4 w-[240px] border-none`}
            >
              <div>Umów się na sesję</div>
            </button>
          </div>
          <div className="p-4 bg-white shadow-lg rounded-[30px] flex flex-col gap-4 justify-center">
            <div className="font-bold text-[#0D81FF] text-[16px]">
              Sesje grupowe
            </div>
            <div className="grid grid-cols-4">
              <Column contents={columnsItems[0]} isFirst />
              <Column contents={columnsItems[1]} />
              <Column contents={columnsItems[2]} />
              <Column contents={columnsItems[3]} isLast />
            </div>
            <div className="font-bold text-[#0D81FF] text-[16px]">
              Sesje indywidualne
            </div>
            <div className="grid grid-cols-3 gap-2">
              {smallItems.map((item, index) => (
                <div
                  key={index}
                  className="rounded-[81px] py-4 px-8 text-white text-[16px] font-bold text-center bg-[#0D81FF]"
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
