import { useState } from 'react';
import { defaultStyles } from '@/utils/defaultStyles';
import { FormField } from './FormField';
import { toast } from 'react-toastify';

export default function ContactFormSection() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isAgreed, setIsAgreed] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (
      !firstName ||
      !lastName ||
      !email ||
      !phoneNumber ||
      !message ||
      !isAgreed
    ) {
      setErrorMessage(
        'Uzupełnij wszystkie pola i zaakceptuj zgodę na przetwarzanie danych',
      );
      return;
    }

    setErrorMessage('');

    const data = {
      imie: firstName,
      nazwisko: lastName,
      email: email,
      numer_telefonu: phoneNumber,
      wiadomosc: message,
    };

    toast.promise(
      fetch('http://api.mentalstep.pl/contact/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((result) => {
          return result;
        })
        .catch((error) => {
          throw error;
        }),
      {
        pending: 'Wysyłanie wiadomości...',
        success: 'Wiadomość została wysłana',
        error: 'Wystąpił błąd przy wysyłaniu',
      },
    );
  };

  return (
    <section
      className={`flex flex-col bg-white${defaultStyles.paddingBig}`}
      id="contact"
    >
      <form
        className="bg-white flex flex-col gap-4 px-8 py-12 rounded-b-[50px]"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col xl:flex-row xl:justify-between xl:items-center gap-4 mb-8">
          <div className="text-[35px] text-black font-bold">
            Skontaktuj się
            <br /> ze mną
          </div>
          <div className="text-[#BDBDBD] text-[16px] xl:w-[50%]">
            Jeśli jesteś zainteresowany moimi usługami lub masz pytania,
            skontaktuj się ze mną. Pomogę Ci odkryć i wykorzystać Twój
            wewnętrzny potencjał!
          </div>
        </div>
        <div className="flex flex-col lg:flex-row gap-4">
          <FormField
            placeholder="Imię"
            onChange={(value) => setFirstName(value)}
            value={firstName}
          />
          <FormField
            placeholder="Nazwisko"
            onChange={(value) => setLastName(value)}
            value={lastName}
          />
        </div>
        <div className="flex flex-col lg:flex-row gap-4">
          <FormField
            placeholder="Email"
            onChange={(value) => setEmail(value)}
            value={email}
          />
          <FormField
            placeholder="Numer Telefonu"
            onChange={(value) => setPhoneNumber(value)}
            value={phoneNumber}
          />
        </div>
        <FormField
          placeholder="Wiadomość"
          onChange={(value) => setMessage(value)}
          value={message}
        />
        {errorMessage && <div className="text-red-500">{errorMessage}</div>}
        <div className="flex flex-col-reverse xl:flex-row xl:justify-between xl:items-center gap-4 mt-8">
          <button
            type="submit"
            className="cursor-pointer uppercase border border-solid border-[#4396FF] rounded-[60px] bg-transparent min-w-[180px] max-w-[180px] px-4 py-4 flex flex-row justify-between items-center"
          >
            <div className="font-[600] text-[#4396FF] text-[16px] uppercase">
              Wyślij
            </div>
            <div className="w-[39px] h-[39px] bg-[#4396FF] rounded-full flex flex-col justify-center items-center">
              <svg
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.42157 5.17157C10.9837 6.73367 10.9837 9.26633 9.42157 10.8284L6.25 14C5.97386 14.2761 5.52614 14.2761 5.25 14C4.97386 13.7239 4.97386 13.2761 5.25 13L7.5 10.75C9.01878 9.23122 9.01878 6.76878 7.5 5.25L5.25 3C4.97386 2.72386 4.97386 2.27614 5.25 2C5.52614 1.72386 5.97386 1.72386 6.25 2L9.42157 5.17157Z"
                  fill="white"
                />
              </svg>
            </div>
          </button>
          <div className="flex flex-row gap-2 xl:w-[60%]">
            <input
              type="radio"
              name="agreements"
              checked={isAgreed}
              onChange={() => setIsAgreed(!isAgreed)}
            />
            <div className="text-[12px]">
              Wyrażam zgodę na przetwarzanie moich danych osobowych przez
              MentalStep Patryk Gąsiorek, reprezentowany przez Patryka Gąsiorka,
              z siedzibą w ul. Radziwoja 47A/2, 61-057 Poznań, Polska, NIP:
              1133124948 w celu i w zakresie niezbędnym do realizacji obsługi
              niniejszego zgłoszenia. Zapoznałem się z treścią informacji o
              sposobie przetwarzania moich danych osobowych udostępnionych pod
              domeną{' '}
              <a href="/rodo" target="_blank">
                mentalstep.pl/rodo.
              </a>
            </div>
          </div>
        </div>
      </form>
    </section>
  );
}
